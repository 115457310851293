export const environment = {
  production: true,
  enableSetRCsubaccount: false,
  enableDashboard: false,
  firstIndexPnl: true,
  sURL: 'https://uat-api.bookiekit.net/v1/api/',
  bURL: 'https://uat-api.bookiekit.net',
  mURL: 'https://uat-market.bookiekit.net',
  riskCalculatorURL: 'https://uat-feed-rust.bookiekit.net/risk_calculator',
  sessionTimeout: 3600000
};
